@font-face {
	font-family: 'Gotham-Book';
	src: local('Gotham-Book'), url(./fonts/Gotham-Book.otf) format('opentype');
}

@font-face {
	font-family: 'Gotham-Book-Italic';
	src: local('Gotham-Book-Italic'),
		url(./fonts/Gotham-BookIta.otf) format('opentype');
	font-display: swap;
}

@font-face {
	font-family: 'Gotham-Medium';
	src: local('Gotham-Medium'), url(./fonts/Gotham-Medium.otf) format('opentype');
	font-display: swap;
}

@font-face {
	font-family: 'Gotham-Medium-Italic';
	src: local('Gotham-Medium-Italic'),
		url(./fonts/Gotham-MediumIta.otf) format('opentype');
	font-display: swap;
}

@font-face {
	font-family: 'Gotham-Black';
	src: local('Gotham-Black'), url(./fonts/Gotham-Black.otf) format('opentype');
	font-display: swap;
}

@font-face {
	font-family: 'Gotham-Bold';
	src: local('Gotham-Bold'), url(./fonts/Gotham-Bold.otf) format('opentype');
	font-display: swap;
}

body {
	margin: 0;
}

video {
	width: calc(100%);
	height: calc(100%);
}
canvas {
	width: calc(100%);
	height: calc(100%);
}

canvas.drawing,
canvas.drawingBuffer {
	position: absolute;
	left: 0;
	top: 0;
}
